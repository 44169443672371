globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"4f86ba76181fcb50751e1e0abf2d64d29005f93c"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { isDev } from "@/utils/nextjs-utils";

const replay = Sentry.replayIntegration({
  // Additional Replay configuration goes in here, for example:
  maskAllText: false,
  blockAllMedia: false,
  maskAllInputs: false,
});

Sentry.init({
  enabled: !isDev(),
  dsn: "https://b183a82b27f7417eb593594e38337ed8@o4504786431246336.ingest.sentry.io/4504786432950272",

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  // debug: true,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,
  normalizeDepth: 6,

  // autoSessionTracking: true,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [replay],

  beforeSend(event) {
    if (event.contexts && event.contexts.browser) {
      const browser = event.contexts.browser;
      if (
        typeof browser.name === "string" &&
        browser.name.includes("Headless")
      ) {
        // Drop the event
        return null;
      }
    }

    // If the event does not match the criteria, return it unmodified
    return event;
  },
});
